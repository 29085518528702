import { default as dashboardLC2DPZvbNAMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/admin/dashboard.vue?macro=true";
import { default as logintcvzx9rbvGMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/admin/login.vue?macro=true";
import { default as choose_45category5LKWXNDMqVMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/choose-category.vue?macro=true";
import { default as defaulttth3OmGHmwMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/default.vue?macro=true";
import { default as indexRkMlzwYmMNMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/index.vue?macro=true";
import { default as claim_45prizeDyq8m7ZjdPMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/claim-prize.vue?macro=true";
import { default as indexA5QLrBADSCMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/index.vue?macro=true";
import { default as max_45playedZy2i7mG0JhMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/max-played.vue?macro=true";
import { default as no_45winXOU67xqWryMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/no-win.vue?macro=true";
import { default as prize_45claimed9D5tRiZ4KbMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/prize-claimed.vue?macro=true";
import { default as winJxAWVVY0ArMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/win.vue?macro=true";
import { default as wrong_45answer8dGJo1fezUMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/wrong-answer.vue?macro=true";
import { default as answer_45correctdC38a9vVfJMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/answer-correct.vue?macro=true";
import { default as beKALZwsM0RlMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/be.vue?macro=true";
import { default as congratulationsqkjiIPPqDCMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/congratulations.vue?macro=true";
import { default as formzIyOl3DdXpMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/form.vue?macro=true";
import { default as loginAu72wtzNnZMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/login.vue?macro=true";
import { default as questiondg5MLQsAokMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/question.vue?macro=true";
import { default as thanksuHKKpvvcjcMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/thanks.vue?macro=true";
import { default as wheelKXAH1NsLibMeta } from "/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/wheel.vue?macro=true";
import { default as choose_45categoryjN3onVU1y5Meta } from "~/pages/choose-category.vue?macro=true";
import { default as wrong_45answer9GjULBXgXmMeta } from "~/pages/quiz/wrong-answer.vue?macro=true";
export default [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardLC2DPZvbNAMeta || {},
    alias: ["/dashboard"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/admin/dashboard.vue")
  },
  {
    name: "admin-login",
    path: "/admin/login",
    meta: logintcvzx9rbvGMeta || {},
    alias: ["/login"],
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/admin/login.vue")
  },
  {
    name: "choose-category",
    path: "/choose-category",
    meta: choose_45category5LKWXNDMqVMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/choose-category.vue")
  },
  {
    name: "default",
    path: "/default",
    meta: defaulttth3OmGHmwMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/default.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexRkMlzwYmMNMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/index.vue")
  },
  {
    name: "quiz-claim-prize",
    path: "/quiz/claim-prize",
    meta: claim_45prizeDyq8m7ZjdPMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/claim-prize.vue")
  },
  {
    name: "quiz",
    path: "/quiz",
    meta: indexA5QLrBADSCMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/index.vue")
  },
  {
    name: "quiz-max-played",
    path: "/quiz/max-played",
    meta: max_45playedZy2i7mG0JhMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/max-played.vue")
  },
  {
    name: "quiz-no-win",
    path: "/quiz/no-win",
    meta: no_45winXOU67xqWryMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/no-win.vue")
  },
  {
    name: "quiz-prize-claimed",
    path: "/quiz/prize-claimed",
    meta: prize_45claimed9D5tRiZ4KbMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/prize-claimed.vue")
  },
  {
    name: "quiz-win",
    path: "/quiz/win",
    meta: winJxAWVVY0ArMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/win.vue")
  },
  {
    name: "quiz-wrong-answer",
    path: "/quiz/wrong-answer",
    meta: wrong_45answer8dGJo1fezUMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/quiz/wrong-answer.vue")
  },
  {
    name: "static-answer-correct",
    path: "/static/answer-correct",
    meta: answer_45correctdC38a9vVfJMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/answer-correct.vue")
  },
  {
    name: "static-be",
    path: "/static/be",
    meta: beKALZwsM0RlMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/be.vue")
  },
  {
    name: "static-congratulations",
    path: "/static/congratulations",
    meta: congratulationsqkjiIPPqDCMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/congratulations.vue")
  },
  {
    name: "static-form",
    path: "/static/form",
    meta: formzIyOl3DdXpMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/form.vue")
  },
  {
    name: "static-login",
    path: "/static/login",
    meta: loginAu72wtzNnZMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/login.vue")
  },
  {
    name: "static-question",
    path: "/static/question",
    meta: questiondg5MLQsAokMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/question.vue")
  },
  {
    name: "static-thanks",
    path: "/static/thanks",
    meta: thanksuHKKpvvcjcMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/thanks.vue")
  },
  {
    name: "static-wheel",
    path: "/static/wheel",
    meta: wheelKXAH1NsLibMeta || {},
    component: () => import("/home/forge/quiz.zuyderland.nl/releases/20241106145615/pages/static/wheel.vue")
  },
  {
    name: "choose-category-nl",
    path: "/kies-categorie",
    meta: choose_45categoryjN3onVU1y5Meta || {},
    component: () => import("~/pages/choose-category.vue")
  },
  {
    name: "wrong-answer-nl",
    path: "/quiz/fout-antwoord",
    meta: wrong_45answer9GjULBXgXmMeta || {},
    component: () => import("~/pages/quiz/wrong-answer.vue")
  }
]