export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const constants: any = useConstants();

    return {
        provide: {
            getCSRF: async function () {
                const url = config.public.apiBaseUrl + constants.routes.api.auth.csrf;
                return await $fetch(url).then(function (response:any) {
                    return response.data;
                })
            },
        }
    }
});