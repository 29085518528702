export const useConstants = () => {
  return {
    routes: {
      api: {
        auth: {
          csrf: "/auth/csrf-cookie",
        },
        prizes: {
          get: "/api/prizes/:id",
          request: "/api/prizes/request",
          claim: "/api/prizes/:id/claim",
          mark: "/api/prizes/:id/mark",
          list: "/api/prizes/filter",
          delete: "/api/prizes",
        },
        games: {
          init: "/api/prizes/start",
          list: "/api/prizes/count",
        },
      },
    },
    app: {
      title: "Zuyderland | ",
      cookie: {
        prefix: "zld-",
      },
      list: {
        limit: 50,
      },
      locale: {
        NL: "nl",
      },
      search: {
        minimumChars: 3,
      },
    },
    status: {
      keys: {
        open: "open",
        delivered: "delivered",
      },
      values: {
        open: "Open",
        delivered: "Uitgegeven",
      },
      options: [
        {
          key: "none",
          label: "Alle tonen",
          value: "none",
        },
        {
          key: "open",
          label: "Open",
          value: "open",
        },
        {
          key: "delivered",
          label: "Uitgegeven",
          value: "delivered",
        },
      ],
    },
  };
};
