<script setup>
useHead({
  title: "404",
});
const error = useError();
</script>

<template>
  <div class="l-default">
    <div
      class="l-container lg:!pl-[200px] lg:!pr-[100px] max-w-[1450px] !pt-[200px] p-section--b"
    >
      <div class="prose">
        <h1>
          Het spijt ons, maar deze pagina bestaat niet… {{ error.statusCode }}
        </h1>
        <a
          href="/"
          target="_self"
          class="button h-50px inline-flex items-center justify-center px-md border border-black !no-underline transition cursor-pointer hover:bg-black hover:text-white mr-xs !mb-xs"
          >Terug naar home</a
        >
      </div>
    </div>
  </div>
</template>
