export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig();
    const constants:any = useConstants();

    return {
        provide: {
            getEmployees: async function ({ page = 1, limit = 50, q = null }) {
                let url = config.public.apiBaseUrl
                    .concat(constants.routes.api.employees.list)
                    .concat('?limit=' + limit)
                    .concat('&page=' + page)

                if (q) {
                    url.concat('&q=' + q)
                }

                return await $fetch(url).then(response => {
                    let employees = response.data;

                    return employees;
                })
            },
        }
    }
});