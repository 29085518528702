export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const constants: any = useConstants();
  const { $apiFetch } = useNuxtApp();

  return {
    provide: {
      initGame: async function () {
        const url = config.public.apiBaseUrl + constants.routes.api.games.init;
        return await $apiFetch(url, {
          method: "post",
        }).then(function (response: any) {
          return response;
        });
      },
      getGamesPlayed: async function () {
        let url = config.public.apiBaseUrl + constants.routes.api.games.list;

        return await $apiFetch(url, { method: "get" }).then(function (
          response: any
        ) {
          return response;
        });
      },
    },
  };
});
