export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const constants: any = useConstants();
  const { $apiFetch } = useNuxtApp();

  return {
    provide: {
      requestPrize: async function () {
        const url =
          config.public.apiBaseUrl + constants.routes.api.prizes.request;
        try {
          return await $apiFetch(url, {
            method: "post",
          }).then(function (response: any) {
            return response?.id;
          });
        } catch (err) {
          console.log(err);
        }
      },
      claimPrize: async function (id: String, body: any) {
        const url = useRouteWithParams({
          url: config.public.apiBaseUrl + constants.routes.api.prizes.claim,
          props: [{ key: "id", value: id }],
        });
        return await $apiFetch(url, {
          method: "post",
          body,
        }).then(function (response: any) {
          return response.data;
        });
      },
      getPrizes: async function ({
        page = 1,
        limit = 50,
        q = null,
        from = null,
        until = null,
        status = null,
      }) {
        let url = config.public.apiBaseUrl
          .concat(constants.routes.api.prizes.list)
          .concat("?limit=" + limit)
          .concat("&page=" + page);

        if (q) {
          url += "&q=" + q;
        }
        if (status) {
          url += "&status=" + status;
        }
        if (from) {
          url += "&from=" + from;
        }
        if (until) {
          url += "&until=" + until;
        }

        return await $apiFetch(url, { method: "POST" }).then(function (
          response: any
        ) {
          return {
            list: response.data,
            pagination: {
              per_page: response.per_page,
              total: response.total,
              from: response.from,
              to: response.to,
            },
          };
        });
      },
      deletePrize: async function (id: String) {
        let url = config.public.apiBaseUrl
          .concat(constants.routes.api.prizes.delete)
          .concat("/" + id);

        return await $apiFetch(url, { method: "DELETE" }).then((response) => {
          return response;
        });
      },
      deletePrizes: async function (ids: any) {
        let url = config.public.apiBaseUrl + constants.routes.api.prizes.delete;

        return await $apiFetch(url, {
          method: "DELETE",
          body: { ids: ids },
        }).then((response) => {
          return response;
        });
      },
      markPrize: async function (id: String, body: any) {
        const url = useRouteWithParams({
          url: config.public.apiBaseUrl + constants.routes.api.prizes.mark,
          props: [{ key: "id", value: id }],
        });
        return await $apiFetch(url, {
          method: "put",
          body,
        }).then(function (response: any) {
          return response.data;
        });
      },
    },
  };
});
